import React, { SetStateAction } from "react";

import {
  FiraLanding,
  FiraLandingConfiguration,
  FiraWidgetConfiguration,
  LandingProduct,
  WidgetType,
} from "../types/Landing.d";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/Store";
import {
  cleanLandingMessage,
  createLandingActionCreator,
  updateLandingActionCreator,
} from "../store/Landing/LandingActions";
import {
  getLandingsByStoreIdService,
  landingUpdateFeatureProductService,
  landingUpdateProductService,
  mergeFeaturedProductsService,
  mergeProductsService,
  saveGlobalLandingSettings,
} from "../services/landing";
import { isNotEmptyOrWhitespace } from "../components/FiraChat/utils/formattersUtils";
import { AlertType, usePopupAlert } from "./AlertProvider";
import { useTranslation } from "react-i18next";
import {
  GlobalLandingSettings,
  useSettingsLanding,
} from "./SettingsLandingProvider";

export enum modulesType {
  PAGE = "PAGE",
  TYPOGRAPHY = "TYPOGRAPHY",
  WIDGETS = "WIDGETS",
  CATALOG = "CATALOG",
}

export const widgetContentInitialState: FiraWidgetConfiguration = {
  widgetId: "",
  previousEvents: "",
  collectionId: "",
  sectionTitle: "",
  bgColor: "",
  fontColor: "",
  displayColor: "",
  detailsColor: "",
  widgetType: WidgetType.vod,
};

const firaLandingConfigurationInitialState: FiraLandingConfiguration = {
  storeUrl: "",
  webLogoUrl: "",
  mobileLogoUrl: "",
  headerHeight: 72,
  headerColor: "",
  footerColor: "",
  bgColor: "",
  logoAlignment: "CENTER",
  mobileCentered: false,
  fontHeader: "",
  fontSizeHeader: 0,
  fontContent: "",
  fontSizeContent: 0,
  fontFooter: "",
  fontSizeFooter: 0,
  widgetContent: widgetContentInitialState,
};

const landingInitialState: FiraLanding = {
  id: "",
  name: "",
  storeId: "",
  firaLandingConfiguration: null,
  active: true,
  shoppingCart: true,
  products: [],
  featuredProducts: [],
  productCatalog: true,
  parametersSetted: false,
  useOwnerDefaultLandingConfig: false,
};

interface LandingConfigContext {
  isGlobalConfig: boolean;
  isLoading: boolean;
  creatingPage: boolean;
  createLanding(): void;
  saveLanding(): void;
  layoutMode: "DESKTOP" | "MOBILE";
  setLayoutMode: React.Dispatch<SetStateAction<"DESKTOP" | "MOBILE">>;
  moduleInView: modulesType;
  setModuleInView: React.Dispatch<SetStateAction<modulesType>>;
  landingConfig: FiraLanding;
  setLandingConfig: React.Dispatch<SetStateAction<FiraLanding>>;
  mobileModuleBar: boolean;
  showModuleBar: boolean;
  setShowModuleBar: React.Dispatch<SetStateAction<boolean>>;
  existingLanding: FiraLanding | null;
  firaLandingConfiguration: FiraLandingConfiguration;
  setFiraLandingConfiguration: React.Dispatch<
    SetStateAction<FiraLandingConfiguration>
  >;
  setFiraWidgetConfig: React.Dispatch<SetStateAction<FiraWidgetConfiguration>>;
  landingId: string;
  saveGlobalLanding(): void;
  refreshLanding(): void;
}
const LandingConfigurationContext =
  React.createContext<LandingConfigContext | null>(null);

interface Props {
  isGlobalConfig: boolean;
  config: FiraLanding | null;
  globalLandingSettings: GlobalLandingSettings;
}
const LandingConfigProvider: React.FC<Props> = ({
  children,
  isGlobalConfig,
  config,
  globalLandingSettings,
}) => {
  const { t } = useTranslation();
  const { brand, landing, utils, authentication } = useSelector(
    (state: RootState) => state
  );
  const dispatch = useDispatch();
  const showAlert = usePopupAlert();
  const { setGlobalConfig, globalLandingConfig } = useSettingsLanding();
  const [layoutMode, setLayoutMode] = React.useState<"DESKTOP" | "MOBILE">(
    "DESKTOP"
  );
  const [moduleInView, setModuleInView] = React.useState<modulesType>(
    modulesType.PAGE
  );
  const [landingConfig, setLandingConfig] =
    React.useState<FiraLanding>(landingInitialState);
  const [mobileModuleBar, setMobileModuleBar] = React.useState<boolean>(false);
  const [showModuleBar, setShowModuleBar] = React.useState<boolean>(true);
  const [existingLanding, setExistingLanding] =
    React.useState<FiraLanding | null>(null);
  const [firaLandingConfiguration, setFiraLandingConfiguration] =
    React.useState<FiraLandingConfiguration>(
      firaLandingConfigurationInitialState
    );
  const [firaWidgetConfig, setFiraWidgetConfig] =
    React.useState<FiraWidgetConfiguration>(widgetContentInitialState);
  const [landingId, setLandingId] = React.useState<string>("");
  const [creatingPage, setCreatingPage] = React.useState<boolean>(true);
  const [featuredProducts, setFeaturedProducts] = React.useState<
    LandingProduct[]
  >([]);
  const [landingProducts, setLandingProducts] = React.useState<
    LandingProduct[]
  >([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const getProducts = () => {
    if (brand.currentStore && existingLanding && existingLanding.id) {
      mergeProductsService(brand.currentStore.id, existingLanding.id).then(
        (val) => {
          if (val.data) {
            setLandingProducts(val.data);
          }
        }
      );
      mergeFeaturedProductsService(
        brand.currentStore.id,
        existingLanding.id
      ).then((val) => {
        if (val.data) {
          setFeaturedProducts(val.data);
        }
      });
    }
  };

  const getLandings = () => {
    if (brand.currentStore)
      getLandingsByStoreIdService(brand.currentStore.id).then((val) => {
        if (val.data.length !== 0) {
          const landing = val.data;

          setExistingLanding(landing);

          setFiraLandingConfiguration(landing.firaLandingConfiguration);
          setCreatingPage(false);
          setLandingId(landing.id);
          let uniqueProducts;

          if (
            landing.featuredProducts !== null &&
            landing.featuredProducts !== undefined
          ) {
            uniqueProducts = Array.from(
              new Map(
                landing.featuredProducts.map((product: LandingProduct) => [
                  product.id,
                  product,
                ])
              ).values()
            );
          }

          setLandingConfig({
            id: landing.id,
            name: landing.name,
            storeId: landing.storeId,
            firaLandingConfiguration: landing.firaLandingConfiguration,
            active: true,
            storeNumber: landing.storeNumber ? landing.storeNumber : "",
            phoneNumber: landing.phoneNumber ? landing.phoneNumber : "",
            shoppingCart: landing.shoppingCart,
            products: landing.products
              ? (uniqueProducts as LandingProduct[])
              : null,
            featuredProducts: landing.featuredProducts
              ? landing.featuredProducts
              : null,
            productCatalog: landing.productCatalog
              ? landing.productCatalog
              : true,
            parametersSetted: landing.parametersSetted,
            useOwnerDefaultLandingConfig: landing.useOwnerDefaultLandingConfig,
          });
        }
      });
  };

  function updateLogos(logos: any) {
    const { webLogo, mobileLogo } = logos;

    if (webLogo === "" && mobileLogo !== "") {
      return { webLogo: mobileLogo, mobileLogo };
    } else if (mobileLogo === "" && webLogo !== "") {
      return { webLogo, mobileLogo: webLogo };
    } else {
      return logos;
    }
  }

  const saveProduct = async () => {
    landingUpdateProductService(
      landingConfig.storeId,
      landingConfig.products ? landingConfig.products : []
    );
  };

  const saveFeaturedProduct = async () => {
    landingUpdateFeatureProductService(
      landingConfig.storeId,
      landingConfig.featuredProducts ? landingConfig.featuredProducts : []
    );
  };

  const createLanding = () => {
    if (brand.currentStore) {
      let body = {
        ...landingConfig,
        firaLandingConfiguration: {
          ...firaLandingConfiguration,
          webLogoUrl: updateLogos(
            {
              webLogo: firaLandingConfiguration.webLogoUrl,
              mobileLogo: firaLandingConfiguration.mobileLogoUrl,
            }.webLogo
          ),
          mobileLogoUrl: updateLogos(
            {
              webLogo: firaLandingConfiguration.webLogoUrl,
              mobileLogo: firaLandingConfiguration.mobileLogoUrl,
            }.mobileLogo
          ),
        },
      };

      if (landingConfig.shoppingCart === true) {
        if (
          landingConfig.phoneNumber &&
          isNotEmptyOrWhitespace(landingConfig.phoneNumber.number)
        ) {
          dispatch(createLandingActionCreator(body));
        } else {
          setIsLoading(false);
          showAlert(AlertType.error, t("views.landing.phoneError"));
          return;
        }
      } else {
        dispatch(createLandingActionCreator(body));
      }
    }
  };

  const saveLanding = () => {
    if (
      brand.currentStore &&
      firaLandingConfiguration !== null &&
      existingLanding &&
      existingLanding.id
    ) {
      setIsLoading(true);
      setLandingConfig({
        ...landingConfig,
        firaLandingConfiguration: firaLandingConfiguration,
      });

      let body = {
        ...landingConfig,
        featuredProducts:
          landingConfig.featuredProducts !== null
            ? landingConfig.featuredProducts
            : featuredProducts,
        products:
          landingConfig.products !== null
            ? landingConfig.products
            : landingProducts,
        firaLandingConfiguration: {
          ...firaLandingConfiguration,
          webLogoUrl: updateLogos(
            {
              webLogo: firaLandingConfiguration.webLogoUrl,
              mobileLogo: firaLandingConfiguration.mobileLogoUrl,
            }.webLogo
          ),
          mobileLogoUrl: updateLogos(
            {
              webLogo: firaLandingConfiguration.webLogoUrl,
              mobileLogo: firaLandingConfiguration.mobileLogoUrl,
            }.mobileLogo
          ),
        },
      };

      if (
        landingConfig.firaLandingConfiguration?.widgetContent.widgetType ===
        WidgetType.products
      ) {
        if (landingConfig.featuredProducts?.length === 0) {
          setIsLoading(false);
          showAlert(
            AlertType.error,
            t("views.landing.widgets.widgetProductsErrorV2")
          );
          return;
        }
      }

      if (
        body.firaLandingConfiguration.widgetContent.previousEvents ===
          "COLLECTIONS" &&
        body.firaLandingConfiguration.widgetContent.collectionId === null
      ) {
        setIsLoading(false);
        showAlert(AlertType.error, t("views.landing.widgets.collectionError"));
        return;
      }

      if (landingConfig.shoppingCart === true) {
        if (
          landingConfig.phoneNumber &&
          isNotEmptyOrWhitespace(landingConfig.phoneNumber.number)
        ) {
          dispatch(updateLandingActionCreator(existingLanding.id, body));
          saveProduct();
        } else {
          setIsLoading(false);
          showAlert(AlertType.error, t("views.landing.phoneError"));
          return;
        }
      } else {
        dispatch(updateLandingActionCreator(existingLanding.id, body));
      }
    }
  };

  const saveGlobalLanding = async () => {
    setIsLoading(true);
    try {
      if (authentication.user && authentication.user.businessClientId) {
        const { businessClientId } = authentication.user;
        const config: FiraLanding = {
          ...landingConfig,
          featuredProducts:
            landingConfig.featuredProducts !== null
              ? landingConfig.featuredProducts
              : featuredProducts,
          products:
            landingConfig.products !== null
              ? landingConfig.products
              : landingProducts,
          firaLandingConfiguration: {
            ...firaLandingConfiguration,
            webLogoUrl: updateLogos(
              {
                webLogo: firaLandingConfiguration.webLogoUrl,
                mobileLogo: firaLandingConfiguration.mobileLogoUrl,
              }.webLogo
            ),
            mobileLogoUrl: updateLogos(
              {
                webLogo: firaLandingConfiguration.webLogoUrl,
                mobileLogo: firaLandingConfiguration.mobileLogoUrl,
              }.mobileLogo
            ),
          },
        };

        await saveGlobalLandingSettings(
          businessClientId,
          globalLandingSettings,
          config
        );
        setGlobalConfig({ ...config });
        setExistingLanding({ ...config });
        setIsLoading(false);
        showAlert(AlertType.success, "Global landing save!");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      showAlert(AlertType.error, JSON.stringify(error));
    }
  };

  const refreshLanding = () => {
    if (brand.currentStore && !isGlobalConfig) {
      setLandingConfig({ ...landingConfig, storeId: brand.currentStore.id });
      getLandings();
    }
    if (isGlobalConfig && config && config.firaLandingConfiguration) {
      setLandingConfig(config);
      setExistingLanding(config);
      setFiraLandingConfiguration(config.firaLandingConfiguration);
      console.log("LANDING", config.firaLandingConfiguration);
    }

    return () => {
      dispatch(cleanLandingMessage());
    };
  };
  const values = {
    isGlobalConfig,
    isLoading,
    saveLanding,
    createLanding,
    creatingPage,
    layoutMode,
    setLayoutMode,
    moduleInView,
    setModuleInView,
    landingConfig,
    setLandingConfig,
    mobileModuleBar,
    showModuleBar,
    setShowModuleBar,
    existingLanding,
    firaLandingConfiguration,
    setFiraLandingConfiguration,
    setFiraWidgetConfig,
    landingId,
    saveGlobalLanding,
    refreshLanding,
  };

  React.useEffect(() => {
    if (utils.screenWidth < 835) {
      setMobileModuleBar(true);
      setShowModuleBar(false);
    } else {
      setMobileModuleBar(false);
      setShowModuleBar(true);
    }
  }, [utils.screenWidth]);

  React.useEffect(() => {
    if (landing.createSuccess) {
      showAlert(AlertType.success, t("views.landing.createSuccess"));
    } else if (landing.error) {
      showAlert(AlertType.error, t("views.landing.createError"));
    }

    if (landing.updateSuccess) {
      setIsLoading(false);
      showAlert(AlertType.success, t("views.landing.updateSuccess"));
      refreshLanding();
    } else if (landing.error) {
      setIsLoading(false);
      showAlert(AlertType.error, t("views.landing.updateError"));
    }
  }, [landing]);

  React.useEffect(() => {
    if (brand.currentStore && !isGlobalConfig) {
      setLandingConfig({ ...landingConfig, storeId: brand.currentStore.id });
      getLandings();
    }
    if (isGlobalConfig && config && config.firaLandingConfiguration) {
      setLandingConfig(config);
      setExistingLanding(config);
      setFiraLandingConfiguration(config.firaLandingConfiguration);
      console.log("LANDING", config.firaLandingConfiguration);
    }

    return () => {
      dispatch(cleanLandingMessage());
    };
  }, []);

  return (
    <LandingConfigurationContext.Provider value={values}>
      {children}
    </LandingConfigurationContext.Provider>
  );
};

export const useLandingConfig = () => {
  const context = React.useContext(LandingConfigurationContext);

  if (context === null) {
    throw new Error("You have to use the context inside LandingConfigProvider");
  }

  return context;
};

export default LandingConfigProvider;
